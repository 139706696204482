<div class="p-3 sidebar-container h-100 scrollable">
    <!-- <div class="bg-image">
      <img src="./../../../assets/auth_bg.png"/>
    </div> -->
    <mat-card>
      <div class="d-flex pt-2 pb-2 align-items-center">
        <fa-icon size="2x" [icon]="faChurch" class="mr-2"></fa-icon>
        <h2 class="m-0">Eredienste</h2>
      </div>
      <mat-card-content>
        <div *ngFor="let item of eredienste" class="d-flex justify-content-between mt-2 mb-2">
          <span>{{item.title}}</span>
          <span>{{item.time}}</span>
        </div>

        <button class="bg-black text-white btn-block mb-3" mat-raised-button>Meer aanbiedinge</button>
        <div class="d-flex justify-content-between">
          <button class="bg-black text-white" mat-raised-button><fa-icon class="mr-2" [icon]="faYoutube"></fa-icon>Video</button>
          <button class="bg-black text-white" mat-raised-button><fa-icon class="mr-2" [icon]="faSoundcloud"></fa-icon>Podcast</button>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="m-3 relative text-white">
      <div class="d-flex pt-2 pb-2 align-items-center">
        <h2 class="m-0">Skakel in</h2>
      </div>
      <div class="d-flex justify-content-around">
        <div><img class="img-fluid" src="./../../../assets/icon_jeug.png"></div>
        <div><img class="img-fluid" src="./../../../assets/icon_kinders.png"></div>
      </div>
    </div>
    <div class="m-3 relative text-white">
      <div class="d-flex pt-2 pb-2 align-items-center">
        <h2 class="m-0 text-white">Insperasie vir die dag</h2>
      </div>
      <div class="d-flex pt-2 pb-2 align-items-center">
        <fa-icon [icon]="faBook" class="mr-2"></fa-icon>
        <h3 class="m-0 text-white">Matthëus 24: 35</h3>
      </div>
      <p>Die hemel en aarde sal verbygaan maar my woorde sal nooit verbygaan nie.</p>
    </div>
    <div class="m-3 relative text-white">
      <div class="d-flex pt-2 pb-2 align-items-center">
        <fa-icon size="2x" [icon]="faCalendar" class="mr-2"></fa-icon>
        <h2 class="m-0 text-white">Gebeure</h2>
      </div>
      <div *ngFor="let item of gebeure" class="d-flex justify-content-between flex-column mt-2 mb-2">
        <span>{{item.date}}</span>
        <h3>{{item.title}}</h3>
      </div>
    </div>
    <hr class="text-white relative bg-white">
    <div class="m-3 relative text-white">
      <div class="d-flex pt-2 pb-2 align-items-center justify-content-between">
        <fa-icon class="text-white" [icon]="faCalendar"></fa-icon>
        <fa-icon class="text-white" [icon]="faCalendar"></fa-icon>
        <fa-icon class="text-white" [icon]="faCalendar"></fa-icon>
        <fa-icon class="text-white" [icon]="faCalendar"></fa-icon>
        <fa-icon class="text-white" [icon]="faCalendar"></fa-icon>
        <fa-icon class="text-white" [icon]="faCalendar"></fa-icon>
      </div>
    </div>
  </div>