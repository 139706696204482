<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>


<div *ngIf="!loading">
  <div class="d-flex justify-content-center">
    <mat-form-field color="accent" class="w-100 mb-3">
      <mat-label>Date</mat-label>
      <input readonly matInput (dateChange)="selectDate($event)" [matDatepicker]="picker1" [ngModel]="date">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>
  <form *ngIf="isAdmin" class="user" [formGroup]="dailyReportForm" (ngSubmit)="saveReport()">
    <div class="flex-wrap d-flex flex-column mb-3">
      <!-- EAT -->
      <h2 class="text-primary">Did {{ student.Name }} eat?</h2>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="eatBreakfast">
        <div class="d-flex flex-wrap align-items-center">
          <mat-label class="flex-1" id="eatBreakfast" for>Breakfast</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="eatBreakfast" formControlName="eat" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3">
            <mat-label>Note</mat-label>
            <input matInput placeholder="Note" name="note" formControlName="note" />

          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="eatSnack1">
        <div class="d-flex flex-wrap align-items-center">
          <mat-label class="flex-1" id="eatSnack1" for>Snack 1</mat-label>
          <div class="flex-wrap  flex-1 d-flex align-items-center">
          <mat-radio-group aria-labelledby="eatSnack1" formControlName="eat" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3">
            <mat-label>Note</mat-label>
            <input matInput placeholder="Note" name="note" formControlName="note" />
          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="eatLunch">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="eatLunch" for>Lunch</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1 ">
          <mat-radio-group aria-labelledby="eatLunch" formControlName="eat" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3">
            <mat-label>Note</mat-label>
            <input matInput placeholder="Note" name="note" formControlName="note" />


          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="eatSnack2">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="eatSnack1" for>Snack 2</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="eatSnack2" formControlName="eat" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3">
            <mat-label>Note</mat-label>
            <input matInput placeholder="Note" name="note" formControlName="note" />
          </mat-form-field>
        </div>
        </div>
      </div>
    </div>
    <div class="flex-wrap d-flex flex-column mb-3">
      <!-- DRINK -->
      <h2 class="text-primary">Did {{ student.Name }} drink?</h2>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="drinkBottle1">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="drinkBottle1" for>Bottle 1</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="drinkBottle1" formControlName="drink" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input min="1" type="number" matInput name="qty" formControlName="qty" />
            <label matSuffix>ml</label>
          </mat-form-field>
<mat-form-field  class="flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input matInput name="time" placeholder="time" formControlName="time" />
          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="drinkBottle2">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="drinkBottle2" for>Bottle 2</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="drinkBottle2" formControlName="drink" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input min="1" type="number" matInput name="qty" formControlName="qty" />
            <label matSuffix>ml</label>
          </mat-form-field>
<mat-form-field  class="flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input matInput name="time" placeholder="time" formControlName="time" />
          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="drinkBottle3">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="drinkBottle3" for>Bottle 3</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="drinkBottle3" formControlName="drink" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input min="1" type="number" matInput name="qty" formControlName="qty" />
            <label matSuffix>ml</label>
          </mat-form-field>
<mat-form-field  class="flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input matInput name="time" placeholder="time" formControlName="time" />
          </mat-form-field>
        </div>
        </div>
      </div>
    </div>
    <div class="flex-wrap d-flex flex-column mb-3">
      <!-- SLEEP -->
      <h2 class="text-primary">Did {{ student.Name }} sleep?</h2>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="sleep1">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="sleep1" for>Sleep 1</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="sleep1" formControlName="sleep" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <mat-label>From</mat-label>
            <input matInput name="from" placeholder="from" formControlName="from" />
          </mat-form-field>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <mat-label>To</mat-label>
            <input matInput name="to" placeholder="to" formControlName="to" />
          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="sleep2">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="sleep2" for>Sleep 2</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="sleep2" formControlName="sleep" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <mat-label>From</mat-label>
            <input matInput name="from" placeholder="from" formControlName="from" />
          </mat-form-field>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <mat-label>To</mat-label>
            <input matInput name="to" placeholder="to" formControlName="to" />
          </mat-form-field>
        </div>
        </div>
      </div>
      <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="sleep3">
        <div class="d-flex align-items-center flex-wrap">
          <mat-label class="flex-1" id="sleep3" for>Sleep 3</mat-label>
          <div class="flex-wrap d-flex align-items-center flex-1">
          <mat-radio-group aria-labelledby="sleep3" formControlName="sleep" class="d-flex">
            <mat-radio-button class="example-radio-button mr-3" [value]="true">
              Yes
            </mat-radio-button>
            <mat-radio-button class="example-radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <mat-label>From</mat-label>
            <input matInput name="from" placeholder="from" formControlName="from" />
          </mat-form-field>
          <mat-form-field  class="flex-1 ml-3 input-sm">
            <mat-label>To</mat-label>
            <input matInput name="to" placeholder="to" formControlName="to" />
          </mat-form-field>
        </div>
        </div>
      </div>
    </div>
    <div class="flex-wrap d-flex flex-column mb-3">
      <!-- MEDICATION -->
      <ng-container formArrayName="medication">
      <h3>Medication</h3>
      <ng-container *ngFor="let medicationForm of medication?.controls; let i = index">
        <div class="flex-wrap medication-form-row d-flex justify-content-between align-items-center" [formGroup]="medicationForm">
          <mat-form-field  class="flex-wrap flex-1 ml-3">
            <mat-label>Medication</mat-label>
            <input matInput placeholder="Name of Medication" name="medication" formControlName="medication" />

          </mat-form-field>
          <mat-form-field  class="flex-wrap flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input min="1" type="number" matInput name="qty" formControlName="qty" />
            <label matSuffix>ml</label>
          </mat-form-field>
          <mat-form-field  class="flex-wrap flex-1 ml-3 input-sm">
            <!-- <mat-label>Qty</mat-label> -->
            <input matInput name="time" placeholder="time" formControlName="time" />
          </mat-form-field>

            <fa-icon class="ml-3 mr-3 link" [icon]="faTrash" (click)="removeMedication(i)"></fa-icon> 
        </div>
    </ng-container>
    <button mat-mini-fab type="button" (click)="addMedication()">
      <fa-icon [icon]="faPlus"></fa-icon> 
    </button>
  </ng-container>
    </div>
    <div class="flex-wrap d-flex flex-column mb-3">
      <!-- DIAPER CHANGE -->
      <ng-container formArrayName="diaperChanges">
      <h3>Diaper changes</h3>

        <ng-container *ngFor="let diaperChangeForm of diaperChanges?.controls; let i = index">
            <div class="flex-wrap diaper-form-row d-flex justify-content-between align-items-center" [formGroup]="diaperChangeForm">
                <mat-form-field  class="mr-2 flex-1 input-sm">
                  <mat-label>Time</mat-label>
                    <input matInput
                           formControlName="time"
                           placeholder="time">
                </mat-form-field>
                <mat-radio-group aria-labelledby="diaper" formControlName="diaper" class="flex-wrap flex-1 d-flex align-items-center">
                  <mat-radio-button class="example-radio-button m-2" [value]="'C'">
                    C
                  </mat-radio-button>
                  <mat-radio-button class="example-radio-button m-2" [value]="'W'">
                    W
                  </mat-radio-button>
                  <mat-radio-button class="example-radio-button m-2" [value]="'D'">
                    D
                  </mat-radio-button>
                </mat-radio-group>
                <fa-icon class="ml-3 mr-3 link" [icon]="faTrash" (click)="removeDiaperChange(i)"></fa-icon> 
            </div>
        </ng-container>
        <button mat-mini-fab type="button" (click)="addDiaperChange()">
          <fa-icon [icon]="faPlus"></fa-icon> 
        </button>

    </ng-container>

<div class="d-flex flex-column mb-3 mt-3">
  <!-- Mood -->
  <h3>{{student.Name}}'s Mood</h3>
<div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="mood">
  <div class="d-flex align-items-center flex-wrap">
    <div class="d-flex align-items-center flex-1 justify-content-around">
    <!-- <mat-radio-group aria-labelledby="sleep3" formControlName="sleep" class="d-flex">
      <mat-radio-button class="example-radio-button mr-3" [value]="'happy'"> -->
        <div class="mood link" (click)="setMood('Happy')" [ngClass]="{'active-mood': this.dailyReportForm.controls['mood'].value === 'Happy'}"><fa-icon class="text-success" size="5x" [icon]="faHappy"></fa-icon></div>
      
        <div class="mood link" (click)="setMood('Neutral')" [ngClass]="{'active-mood': this.dailyReportForm.controls['mood'].value === 'Neutral'}"><fa-icon class="text-warning" size="5x" [icon]="faNeutral"></fa-icon></div>
      
        <div class="mood link" (click)="setMood('Sad')" [ngClass]="{'active-mood': this.dailyReportForm.controls['mood'].value === 'Sad'}"><fa-icon class="text-danger" size="5x" [icon]="faSad"></fa-icon></div>
      
  </div>
  </div>
</div>
</div>
<div class="d-flex flex-column mb-3 mt-3">
    <h3>Additional notes</h3>
      <mat-form-field appearance="outline">
        <mat-label>Notes</mat-label>
        <input matInput placeholder="notes" formControlName="notes" />
      </mat-form-field>
    </div>
  </div>

    <mat-toolbar class="d-flex justify-content-end">
      <button
        type="submit"
        [disabled]="!dailyReportForm.valid || loading"
        mat-raised-button
        color="primary"
      >
        Save
      </button>
    </mat-toolbar>
  </form>

  <ng-container *ngIf="!isAdmin">
    <div class="row">
      <div class="col-sm-3 mb-3">
        <mat-card>
        <h2 class="text-primary">Did {{ student.Name }} eat?</h2>
        <div class="d-flex">
          <h4 class="flex-1" id="eatBreakfast" for>Breakfast</h4>
          <fa-icon [icon]="dailyReport?.Breakfast?.eat ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="eatSnack1" for>Snack 1</h4>
          <fa-icon [icon]="dailyReport?.EatSnack1?.eat ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="eatSnack1" for>Lunch</h4>
          <fa-icon [icon]="dailyReport?.EatLunch?.eat ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="eatSnack2" for>Snack 2</h4>
          <fa-icon [icon]="dailyReport?.EatSnack2?.eat ? faTrue : faFalse"></fa-icon>
        </div>
        </mat-card>
      </div>
      <div class="col-sm-3 mb-3">
        <mat-card>
        <h2 class="text-primary">Did {{ student.Name }} drink?</h2>
        <div class="d-flex">
          <h4 class="flex-1" id="drinkBottle1" for>Bottle 1</h4>
          <fa-icon [icon]="dailyReport?.DrinkBottle1?.drink ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="drinkBottle2" for>Bottle 2</h4>
          <fa-icon [icon]="dailyReport?.DrinkBottle2?.drink ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="drinkBottle3" for>Bottle 3</h4>
          <fa-icon [icon]="dailyReport?.DrinkBottle3?.drink ? faTrue : faFalse"></fa-icon>
        </div>
        </mat-card>
      </div>
      <div class="col-sm-3 mb-3">
        <mat-card>
        <h2 class="text-primary">Did {{ student.Name }} sleep?</h2>
        <div class="d-flex">
          <h4 class="flex-1" id="sleepBottle1" for>Sleep 1</h4>
          <fa-icon [icon]="dailyReport?.Sleep1?.sleep ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="sleepBottle2" for>Sleep 2</h4>
          <fa-icon [icon]="dailyReport?.Sleep2?.sleep ? faTrue : faFalse"></fa-icon>
        </div>
        <div class="d-flex">
          <h4 class="flex-1" id="sleepBottle3" for>Sleep 3</h4>
          <fa-icon [icon]="dailyReport?.Sleep3?.sleep ? faTrue : faFalse"></fa-icon>
        </div>
        </mat-card>
      </div>
      <div class="col-sm-3 mb-3">
        <mat-card>
        <h2 class="text-primary">Medication</h2>
        <ng-container *ngFor="let m of dailyReport.Medication">
          <div class="d-flex flex-column">
            <h4 class="flex-1"><fa-icon [icon]="faMed"></fa-icon> {{m.qty ? m.qty + 'ml' : ''}} {{m.medication}} {{m.time ? '@ ' + m.time : ''}}</h4>
          </div>
        </ng-container>
        </mat-card>
      </div>
      <div class="col-sm-3 mb-3">
        <mat-card>
        <h2 class="text-primary">Diaper changes</h2>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
