import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from "../environments/environment";
import * as Parse from "parse";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Kruin';
  constructor() {
    (Parse as any).initialize(environment.PARSE_APP_ID, environment.PARSE_JS_KEY);
    (Parse as any).serverURL = environment.serverURL;
    (Parse as any).liveQueryServerURL = environment.liveQueryServerURL;
  }
  ngOnInit() {
    // const { SplashScreen } = Plugins
    // SplashScreen.hide();
  }
}

