  import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StudentService } from 'src/app/admin/services/student.service';
import { SideNavService } from 'src/app/admin/services/side-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { StudentSearchCriteria } from 'backend/interfaces/student.interface';

@Component({
  selector: 'app-search-student',
  templateUrl: './search-student.component.html',
  styleUrls: ['./search-student.component.scss']
})
export class SearchStudentComponent implements OnInit {
  loading = false;
  searchStudentForm: UntypedFormGroup;
  noResults = false;
  faSearch = faSearch;
  searchPhrase: StudentSearchCriteria;
  constructor(private fb: UntypedFormBuilder, private studentService: StudentService, private sideNavService: SideNavService, private router: Router, private ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.searchStudentForm = this.fb.group(
      {
        name: [''],
        surname: ['']
      });
    this.studentService.studentsSearch$.subscribe(r => {
      this.searchPhrase = r;
      this.searchStudentForm.patchValue({...r})
    })
      
  }
  search(){
    this.noResults = false;
    this.loading = true;
    this.studentService.searchStudent(this.searchStudentForm.value).then(async res => {
      if(res.students.length > 0){
        this.studentService.studentSearch.next(this.searchStudentForm.value);
        this.studentService.students.next(res.students);
        this.studentService.studentCount.next(res.count);
        this.router.navigate(['admin','students']);
        this.sideNavService.close();
      } else {
        this.noResults = true;
      }
      this.loading = false;
    })
  }

  async clearSearch(){
    this.studentService.studentSearch.next({name: '', surname: ''});
    this.loading = true;
    await this.studentService.getStudentCount();
    await this.studentService.getStudents(10, 0);
    this.loading = false;
  }
}
