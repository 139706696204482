<div class="p-2 bg-50perc-white">
    <div class="text-center">
        <h1 class="h4 text-gray-900 mb-2">Forgot Your Password?</h1>
        <div class="text-center mb-4">
            <button mat-button routerLink="/login">Already have an account? Login!</button>
        </div>
        <p class="mb-4">We get it, stuff happens. Just enter your email address below
            and we'll send you a link to reset your password!</p>
    </div>
    <form class="user">
        <div class="form-group">
            <input name="email" type="email" class="form-control form-control-user" aria-describedby="emailHelp"
                placeholder="Email" [(ngModel)]="email" (focus)="errorMessage = ''">
        </div>
        <button (click)="forgotPassword()" class="btn btn-secondary btn-user btn-block">
            Reset Password
        </button>
    </form>
</div>