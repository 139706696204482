import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faDownload, faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Student } from 'backend/interfaces/student.interface';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SignaturePadComponent } from '../signature-pad/signature-pad.component';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { FileUpload } from 'backend/interfaces/file-upload';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
@Component({
  selector: 'app-student-consent-form',
  templateUrl: './student-consent-form.component.html',
  styleUrls: ['./student-consent-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentConsentFormComponent implements OnInit, AfterViewInit {
  consentForm: UntypedFormGroup;
  faPhone = faPhone;
  faHome = faHome;
  faDownload = faDownload;
  faEnvelope = faEnvelope;
  signature: any;
  signed = false;
  pdf: FileUpload;
  saving = false;

  @ViewChild('signatureElement', {static: false}) signatureElement: ElementRef;
  @ViewChild('TreatmentConsent', {static: false}) TreatmentConsent: ElementRef;
  @Input() student?: Student;
  @Output() emitStudent = new EventEmitter<Object>();
  @Output() emitBack = new EventEmitter<Boolean>();
  panelOpenState = false;
  company = {
    name: 'Kruin',
    practitioner: 'Name Surname',
    prNo: '',
    address: {
      number: '',
      street: '',
      suburb: '',
      city: '',
      code: ''
    },
    contactNumber: '',
    email: ''
  }
  constructor(private fb: UntypedFormBuilder, public dialog: MatDialog) { }

async ngOnInit(): Promise<void> {
    this.consentForm = this.fb.group({
      InformedFinancialConsent: [this.student.InformedFinancialConsent, Validators.required],
      InformedTreatmentConsent: [this.student.InformedTreatmentConsent, Validators.required],
      Document: [this.student.Document ? this.student.Document: {source: '', name: ''}, Validators.required]});
}

treatmentConsent = [
  "the undersigned, hereby request and consent to...",
   ""
];
financialConsent = [
  "the undersigned, hereby accept full financial responsibility for this account. I confirm that all details provided are all true and correct. I am aware of the costs of the treatment.",
  "I understand that should I not cancel an appointment within four (4) hours of the set appointment I will be invoiced for the full amount.",
  "I understand that this practice is contracted out of medical aid, and must settle my account directly with this office and then submit my receipted invoice to my medical aid for reimbursement."
]

cancelationNote = "Appointments not cancelled within 4 hours of the due time will be Charged in full tariff. This practice is contracted out of Medical Aid. You are to please settle your account directly with this office and then submit your receipted invoice to your Medical Aid for reimbursement.";

ngAfterViewInit(): void {
}

async saveStudentConsent(){
  this.saving = true;
  await this.generatePDF();
  setTimeout(() => {
    this.emitStudent.emit({'student': {
      ID: this.student.ID,
      InformedFinancialConsent: this.consentForm.controls['InformedFinancialConsent'].value,
      InformedTreatmentConsent: this.consentForm.controls['InformedTreatmentConsent'].value,
      Document: this.consentForm.controls['Document'].value
    }, 'signature': this.signature});
    this.saving = false;
  }, 4000);
}

openDialog(): void {
  const dialogRef = this.dialog.open(SignaturePadComponent, {
    data: {name: this.student.Name, surname: this.student.Surname},
    width: '80%',
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result){
      this.signature = result.data;
      this.signatureElement.nativeElement.setAttribute('src', this.signature);
      this.signed = true;
    } else {
      this.signed = false;
    }
  });
}

getErrorMessage() {
  if (this.consentForm.controls['Name'].hasError('required')) {
    return 'You must enter a value';
  }

  return this.consentForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
}


  async generatePDF(action?: string): Promise<void> {
    const personNameAndSurname = this.student.Name + ' ' + this.student.Surname;
    let docDefinition = {
      content: [
        {
          columns: [
            [
              {
                margin: [-40, -40, -40, 0],
                width: 595,
                image: await this.getBase64ImageFromURL('../../../assets/letterhead.png')
              }
            ]
          ]
        },
        {
          margin: [0,10,0,5],
          columns: [
            {
              text: 'Client Details',
              fontSize: 10,
              bold: true,
            },
            {
              text: 'Medical Aid',
              fontSize: 10,
              bold: true,
            }
          ]
        },
        {
          columns: [
          {
            // Student Particulars
            fontSize: 10,
            stack: [
              {
                columns: [
                  {
                    text: 'Name: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Name, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Surname: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Surname, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'ID Number: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.ID, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Mobile: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Mobile, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Email: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Email, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Address: ', margin: [0,1], width: '25%'
                  },
                  {
                    text: this.student.Address, margin: [0,1], width: 'auto'
                  }
                ]
              },
            ],
          },
          {
            // Medical Aid Particulars
            fontSize: 10,
            stack: [
              {
                columns: [
                  {
                    text: 'Main Member: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.student.MedicalAidMainMember, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Medical Aid Number: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.student.MedicalAidNumber, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Medical Aid Name: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.student.MedicalAidName, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'Notes: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.student.Notes, margin: [0,1], width: 'auto'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: 'HealthProblems: ', margin: [0,1], width: '50%'
                  },
                  {
                    text: this.student.HealthProblems, margin: [0,1], width: 'auto'
                  }
                ]
              }
            ],
          }
        ]},
        {
          text: 'Informed Consent',
          fontSize: 10,
          margin: [0, 10, 0, 0],
          bold: true
        },
        {

              stack: [
                {
                  text: 'I, '+ personNameAndSurname + ', ' + this.treatmentConsent[0], margin: [0,5]
                },
                {
                  text: this.treatmentConsent[1], 
                  margin: [0,5]
                },
                {
                  text: this.treatmentConsent[2], 
                  margin: [0,5]
                }
              ],
              fontSize: 10

        },
        {
          text: 'Informed Consent to the Financial Responsibility of My Account',
          fontSize: 10,
          margin: [0, 10, 0, 0],
          bold: true
        },
        {

          stack: [
            {
              text: 'I, '+ personNameAndSurname + ', ' + this.financialConsent[0], margin: [0,5]
            },
            {
              text: this.financialConsent[1], 
              margin: [0,5]
            },
            {
              text: this.financialConsent[2], 
              margin: [0,5]
            }
          ],
          fontSize: 10

        },
        { 
          image: this.signature,
          fit: [80,80],
          style: 'sectionHeader'
        },
        {
          text: "Students Signature (Parent/Guardian of student should the student be younger than 18)"
        }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0,15,0,15]          
        }
      }
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    await pdfDocGenerator.getBase64((data) => {
      const pdf = {
        name: this.student.ID+'_studentConsent'+'.pdf',
        source: data
      };
      setTimeout(() => {
        this.consentForm.controls['Document'].setValue(pdf);
      }, 500);
    })
    // if(action==='view'){
    //   pdfDocGenerator.open(); 
    // }
  }


  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  back(){
    this.emitBack.emit(true);
  }
  download() {
    const linkSource = this.student.Document;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource.source;
    downloadLink.target = '_blank';
    downloadLink.download = linkSource.name;
    downloadLink.click();
  }
}