import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
// import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subject, observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Service } from 'backend/interfaces/service.interface';
import * as moment from 'moment';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { faCalendar, faCalendarAlt, faChild, faMapMarker, faMapMarkerAlt, faNotesMedical, faPlus, faUserAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ParentDataService } from 'src/app/parent/services/data.service';

export interface TimeSlot {
  time: string,
  selected: boolean
}
@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  // @ViewChild('stepper') stepper: MatStepper;
  @Input('parent') parent;
  @Output('complete') complete: EventEmitter<any> = new EventEmitter(null);
  @Output('createStudent') createStudent: EventEmitter<any> = new EventEmitter(null);
  @Output('createParent') createParent: EventEmitter<any> = new EventEmitter(null);
  
  
  titleList = [
    'Mnr', 'Me'
  ];
  maritalStatusList = [
    'Getroud',	'Ongetroud',	'Geskei',	'Wewenaar',	'weduwee',
  ];
  memberStatusList = [
    'Nuwe Intrekker','Belydend',	'Doop',	'Ongedoop',
  ]
  faUserPlus = faUserPlus;
  faChild = faChild;
  faNote = faNotesMedical;
  faCalendar = faCalendar;
  faPlus = faPlus;
  faCalendarAlt = faCalendarAlt;
  faMapMarker = faMapMarker;
  faMapMarkerAlt = faMapMarkerAlt;
  faUser = faUserAlt;
  loading = false;
  loadingPractitioners = false;
  services: Service[];
  locations: Location[];
  selectedLocation: string;
  selectedDate: Date;
  startAt = new Date('2023/09/11');
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  busyTimes: Date[];
  timeSlots: TimeSlot[] = [];
  year: any;
  DayAndDate: string;
  practitioners: Practitioner[] = [];
  practitioners$: BehaviorSubject<Practitioner[]> = new BehaviorSubject([]);
  selectedPractitioner = 'Any Available';
  hasSpouse = false;
  hasKids = false;
  appointmentTypeForm = this._formBuilder.group({
    type: ['', Validators.required],
    duration: ['']
  });
  user;
  appointmentLocationForm = this._formBuilder.group({
    location: ['', Validators.required]
  });
  appointmentDateForm = this._formBuilder.group({
    date: ['', Validators.required]
  });
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }
  profileForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    AdditionalNotes: [''],
    Title: [''],
    Birthday: [''],
    ID: [''],
    Address: [''],
    PostalAddress: [''],
    MembershipStatus: [''],
    MaritalStatus:[''],
    MariageDate: [''],
    PreviousChurch: [''],
    Occupation: [''],
    Employer: ['']
  });
  spouseForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    AdditionalNotes: [''],
    Title: [''],
    Birthday: [],
    ID: [''],
    Address: [''],
    PostalAddress: [''],
    MembershipStatus: [''],
    MaritalStatus:[''],
    MariageDate: [''],
    PreviousChurch: [''],
    Occupation: [''],
    Employer: ['']
  });

  childForm1 = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    AdditionalNotes: [''],
    Title: [''],
    Birthday: [],
    ID: [''],
    Address: [''],
    PostalAddress: [''],
    MembershipStatus: [''],
    MaritalStatus:[''],
    MariageDate: [''],
    PreviousChurch: [''],
    Occupation: [''],
    Employer: [''],
    HasSpouse: [''],
    PartnerId: ['']
  });
  bookingAgentForm = this._formBuilder.group({
    Name: ['', Validators.required],
    Surname: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    RelationToStudent: ['', Validators.required]
  });
  // stepperOrientation: Observable<StepperOrientation>;
  students;
  constructor(private router: Router, private _formBuilder: UntypedFormBuilder, private authService: AuthService, private dataService: ParentDataService) {

  }

  async ngOnInit(): Promise<void> {

if(!this.parent){
  this.authService.$User.subscribe(res => {
    if (res) {
      this.parent = res;
      // this.tempParent = {Name: this.parent?.Name, Surname: this.parent?.Surname, Email: this.parent?.Email, Mobile: this.parent?.Mobile}
      this.profileForm.controls['MembershipStatus'].setValue('Nuwe Intrekker')
    }
  });
}
        this.profileForm.patchValue({
          Title: this.parent.get('Title'),
          Name: this.parent.get('Name'),
          Surname: this.parent.get('Surname'),
          Mobile: this.parent.get('Mobile'),
          Email: this.parent.get('email'),
          ID: this.parent.get('ID'),
          Birthday: this.parent.get('ID') ? this.getDateOfBirth(this.parent.get('ID')) : ''
        })

    this.dataService.students$.subscribe(res => {
      this.students = res;
    })
  }

  submit(): void{
    this.loading = true;
    this.dataService.saveParent(this.profileForm.value).then(res => {
      this.dataService.parent.next(res);
      this.complete.emit();
      this.loading = false;
    }).catch(err => console.log(err))
  }

  goHome(){
    this.router.navigateByUrl('');
  }

  addSpouse(){
    this.createParent.emit();
  }

  addChild(){
    this.createStudent.emit();
  }

  getDateOfBirth(idNumber):Date {
    let birthday;
    const currentYear = new Date().getFullYear();
    const year = idNumber.substring(0, 2);
    const month = idNumber.substring(2, 4);
    const day = idNumber.substring(4, 6);
    var date = new Date(month + "/" + day + "/" + year);
    if(date.getFullYear() > currentYear){
      birthday = new Date(new Date(month + "/" + day + "/" + '19'+year))
    }else{
      birthday = new Date(new Date(month + "/" + day + "/" + year))
    }
    return birthday
  }

}