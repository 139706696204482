<div class="bg-image-1">
        <div class="d-flex justify-content-between align-items-center">
            <img class="img-fluid logo" src="assets/icon.png"/>
            <button mat-raised-button color="accent" (click)="goHome()">Home</button>
            <div class="spacer"></div>
            <button mat-raised-button color="primary" (click)="continueToSignIn()">Login</button>
            <h1 class="headline mb-0 m-3 text-white">New Appointment</h1>
        </div>
</div>  
<mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'" [value]="0">
</mat-progress-bar>
<div class="container-fluid">
  <mat-stepper #stepper linear
    [orientation]="(stepperOrientation | async)!">
    <mat-step [stepControl]="appointmentTypeForm" [label]="appointmentTypeForm.controls['type'].value ? appointmentTypeForm.controls['type'].value : 'How can we help?'">
              <h1 class="mat-headline mt-4 mb-5">Please select a appointment type</h1>
                <div class="row d-flex flex-wrap justify-content-center" *ngIf="services">
                    <mat-card class="p-3 d-flex flex-column col-lg-3 col-md-4 col-sm-12 m-2 link" (click)="setAppointmentType(service.title, service.duration)" *ngFor="let service of services">
                        <mat-card-header class="m-0">
                          <mat-card-title>{{service.title}}</mat-card-title>
                          <mat-card-subtitle class="mb-1">{{service.duration}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content class="mt-0 ml-3 mr-3 mb-0 flex-1">
                            <p>{{service.description}}</p>
                            <p>{{service.note}}</p>
                            <mat-card-subtitle class="mb-1">{{service.price}}</mat-card-subtitle>
                        </mat-card-content>
                    </mat-card>
                </div>
    </mat-step>
    <mat-step [stepControl]="appointmentLocationForm" [label]="appointmentLocationForm.controls['location'].value ? appointmentLocationForm.controls['location'].value : 'Where can we help ?'" >
        <!-- <div class="row d-flex flex-column justify-content-center align-items-center"> -->
            <!-- <div class="d-flex justify-content-center flex-column"> -->
              
          <!-- </div> -->
          <div class="row d-flex justify-content-center">
            <div class="relative p-0 d-flex flex-column col-lg-5 col-md-5 col-sm-12 m-2">
              <h1 class="mat-headline mt-4 mb-5">Please select a location</h1>
              <mat-card class="link mb-3" (click)="setLocation(location.name)" *ngFor="let location of locations">
              <div *ngIf="locations" class="d-flex flex-wrap justify-content-center flex-column">
                    <mat-card-header class="m-0 pt-3">
                      <mat-card-title><fa-icon [icon]="faMapMarkerAlt"></fa-icon> {{location.name}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="mt-0 ml-3 mr-3 mb-0 flex-1">
                        <p>{{location.address}}</p>
                    </mat-card-content>
              </div>
              </mat-card>
          </div>
            <div *ngIf="selectedLocation" class="relative p-0 d-flex flex-column col-lg-5 col-md-5 col-sm-12 m-2 justify-content-start">
              <h1 class="mat-headline mt-4 mb-5">Do you have a preferred provider?</h1>
              <mat-card class="link mb-3" (click)="setPractitioner('Any Available')" [ngClass]="{'border-primary': selectedPractitioner === 'Any Available'}">
                <div class="d-flex flex-wrap justify-content-start flex-column">
                      <mat-card-header class="m-0 pt-3">
                        <mat-card-title><fa-icon [icon]="faUser" class="mr-3"></fa-icon> Any Available</mat-card-title>
                      </mat-card-header>
                </div>
                </mat-card>

              <ng-container *ngIf="practitioners$ | async as practitioners">
                <mat-card *ngFor="let practitioner of practitioners" class="link mb-3" (click)="setPractitioner(practitioner.id)" [ngClass]="{'border-primary': selectedPractitioner === practitioner.id}">
                  <div class="d-flex flex-wrap justify-content-center flex-column">
                        <mat-card-header class="m-0 pt-3">
                          <mat-card-title><fa-icon [icon]="faUser" class="mr-3"></fa-icon> {{practitioner.name}} {{practitioner.surname}} </mat-card-title>
                        </mat-card-header>
                  </div>
                  </mat-card>
                </ng-container>
              <!-- <mat-radio-group class="radio-group" fxLayout="column" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPractitioner">
                <mat-radio-button class="radio-button" value="Any Available">
                    <fa-icon [icon]="faUser" class="mr-3"></fa-icon> Any Available 
                </mat-radio-button>
                <ng-container *ngFor="let practitioner of practitioners">
                <mat-radio-button class="radio-button" *ngIf="practitioner.Location === selectedLocation" [value]="practitioner.Name">

                    <fa-icon [icon]="faUser" class="mr-3"></fa-icon> {{practitioner.Name}} {{practitioner.Surname}} 

                </mat-radio-button>
                </ng-container>
              </mat-radio-group> -->

              <!-- <div *ngFor="let time of times" class="pill">{{time | date}}</div> -->
              <button mat-raised-button color="primary" (click)="stepper.next()">Next</button>
              <div class="d-flex justify-content-start loader flex-column align-items-center" *ngIf="practitioners.length < 1">
                <div>
                    <mat-spinner color="accent"></mat-spinner>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3583.7047657957687!2d28.00841351502744!3d-26.075903383495067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9574875e2d405f%3A0xda8b8f0326a71162!2sBryanston%20Gate!5e0!3m2!1sen!2sza!4v1682413230664!5m2!1sen!2sza" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div> -->
    </mat-step>
    <mat-step [stepControl]="appointmentDateForm" [label]="appointmentDateForm.controls['date'].value ? (appointmentDateForm.controls['date'].value | date: 'dd MMMM, HH:mm') : 'When can we help?'">
      <div class="row d-flex flex-wrap justify-content-center">
          <mat-card class="p-0 d-flex flex-column col-lg-5 col-md-8 col-sm-12 m-2">
            <h1 class="mat-headline m-3 mb-0">Select a date</h1>
            <mat-calendar
            [selected]="selectedDate" 
            [dateFilter]="myDateFilter"
            [minDate]="minDate"
            (selectedChange)="selectDate($event)"></mat-calendar>
          </mat-card>
          <mat-card *ngIf="selectedDate" class="relative p-0 d-flex flex-column col-lg-5 col-md-3 col-sm-12 m-2">
            <h1 class="mat-headline m-3 mb-0">Select a time</h1>
            <!-- <div *ngFor="let time of times" class="pill">{{time | date}}</div> -->
            <div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="timeSlots.length < 1">
              <div>
                  <mat-spinner color="accent"></mat-spinner>
              </div>
            </div>
            <mat-chip-list *ngIf="timeSlots.length > 0" class="mat-chip-list-stacked m-3">
              <mat-chip
                #chipRef="matChip"
                *ngFor="let slot of timeSlots"
                class="link justify-content-center"
                (click)="chipRef.selectViaInteraction(); selectTimeSlot(slot);"
                [selected]="slot.selected"
              >
              {{slot.time | date: 'HH:mm':'UTC +2'}}
              </mat-chip>
            </mat-chip-list>
            
            </mat-card>
      </div>
  </mat-step>
    <mat-step [stepControl]="profileForm" label="Student info">

      <div class="row d-flex justify-content-center">
    <div class="col-md-6">

      <h1 class="mat-headline mt-4 mb-5">Profile</h1>
      <form class="user" [formGroup]="profileForm" class="d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" name="name" formControlName="Name">
            <mat-error *ngIf="profileForm.controls.Name.invalid">Name is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Surname</mat-label>
            <input matInput placeholder="Surname" formControlName="Surname">
            <mat-error *ngIf="profileForm.controls.Surname.invalid">Surname is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Mobile</mat-label>
            <input matInput placeholder="Mobile" formControlName="Mobile">
            <mat-error *ngIf="profileForm.controls.Mobile.invalid">Mobile is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="Email">
            <mat-error *ngIf="profileForm.controls.Email.invalid">Email is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Additional Notes</mat-label>
          <input matInput placeholder="Notes" formControlName="AdditionalNotes">
      </mat-form-field>
        <mat-checkbox formControlName="BookingForSomeoneElse">Are you making an appointment for someone else?</mat-checkbox>
    </form>
    </div>
    <div class="col-md-6" *ngIf="profileForm.controls['BookingForSomeoneElse'].value">
      <h1 class="mat-headline mt-4 mb-5">Your Details</h1>
      <form class="user" [formGroup]="bookingAgentForm" class="d-flex flex-column">
        <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Name" name="name" formControlName="Name">
            <mat-error *ngIf="bookingAgentForm.controls.Name.invalid">Name is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Surname</mat-label>
            <input matInput placeholder="Surname" formControlName="Surname">
            <mat-error *ngIf="bookingAgentForm.controls.Surname.invalid">Surname is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Mobile</mat-label>
            <input matInput placeholder="Mobile" formControlName="Mobile">
            <mat-error *ngIf="bookingAgentForm.controls.Mobile.invalid">Mobile is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" formControlName="Email">
            <mat-error *ngIf="bookingAgentForm.controls.Email.invalid">Email is Required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Relationship with Student</mat-label>
          <input matInput placeholder="Relation" formControlName="RelationToStudent">
          <mat-error *ngIf="bookingAgentForm.controls.RelationToStudent.invalid">Relationship is Required</mat-error>
      </mat-form-field>
        <!-- <mat-checkbox formControlName="IsPrimaryContact">I'm the student's primary contact</mat-checkbox> -->
    </form>
    </div>
    </div>
    <div class="row d-flex justify-content-center">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button (click)="makeAppointment()">Submit</button>
    </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>See you soon!</ng-template>
      <div class="row d-flex justify-content-center">
        <div class="col-md-6">
          <mat-card>
            <mat-card-header class="m-0 pt-3">
              <mat-card-title>Thank you</mat-card-title>
              <mat-card-subtitle>See you soon!</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="p-3">
              <div class="mat-title d-flex">
                <div class="p-3">
                <fa-icon [icon]="faNote"></fa-icon>
                </div>
                <div class="d-flex align-items-center">{{appointmentTypeForm.controls['type'].value}}</div>
                </div>
              <div class="mat-title d-flex">
                <div class="p-3">
                <fa-icon [icon]="faMapMarkerAlt"></fa-icon>
                </div>
                <div class="d-flex align-items-center">{{appointmentLocationForm.controls['location'].value}}</div>
                </div>
              <div class="mat-title d-flex">
                <div class="p-3">
                <fa-icon [icon]="faCalendarAlt"></fa-icon>
                </div>
                <div class="d-flex align-items-center">{{appointmentDateForm.controls['date'].value | date: 'dd MMMM yyyy, HH:mm'}}</div>
                </div>
                <div class="mat-title d-flex">
                  <div class="p-3">
                  <fa-icon [icon]="faUser"></fa-icon>
                  </div>
                  <div class="d-flex align-items-center">{{profileForm.controls['Name'].value}} {{profileForm.controls['Surname'].value}}</div>
                  </div>
                  <button mat-raised-button color="primary" (click)="continueToSignIn()">Sign in or create your profile</button>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
  </div>