import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { CalendarEvent } from 'angular-calendar';

export enum SidenavActions {
  NewAppointment = 'New Appointment',
  NewParent = 'Skep Ouer',
  NewStudent = 'Skep leerder',
  SearchParent = 'Soek ouer',
  SearchStudent = 'Soek leerder',
  UpdateAppointment = 'Update Appointment',
  ReviewAppointment = 'Appointment Request',
  NewLocation = 'Create Location',
  SearchLocation = 'Find Location',
  NewMealPlan = 'Create Meal-plan',
  SearchMealPlan = 'Find Meal-plan',
  NewMeal = 'Create Meal',
  SearchMeal = 'Find Meal',
  NewService = 'Create Service',
  SearchService = 'Find Service',
  NewPractitioner = 'Create Teacher',
  SearchPractitioner = 'Find Teacher',
  NewGroup = 'Skep Groep',
  UpdateGroup = 'Groep',
  SearchGroup = 'Find Class',
}

@Injectable({
  providedIn: 'root'
})


export class SideNavService {
  sidenav: MatSidenav;
  sidenavAction: SidenavActions;
  loading = false;
  newParentEmail: string;
  public newBookingForm: CalendarEvent;
  constructor() { }

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  open(action: SidenavActions, newParentEmail?: string) {
      this.sidenavAction = action;
      this.newParentEmail = newParentEmail; 
      console.log(this.sidenavAction)
      return this.sidenav.open();
  }


  close() {
      this.loading = false;
      return this.sidenav.close();
  }

  toggle(): void {
    this.loading = false;
    this.sidenav.toggle();
  }

  
}
