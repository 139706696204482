import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Student, StudentConsent } from 'backend/interfaces/student.interface';
import { Observable } from 'rxjs';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatStepper, StepperOrientation} from '@angular/material/stepper';
import {map} from 'rxjs/operators';
import { StudentService } from 'src/app/admin/services/student.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';
import { ParentDataService } from 'src/app/parent/services/data.service';
import { Location } from '@angular/common';
import { Parent } from 'backend/interfaces/parent.interface';
import { DataService } from 'src/app/services/data.service';
import { GroupService } from 'src/app/admin/services/group.service';
import { Group } from 'backend/interfaces/group.interface';
import { faEye, faUser } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss']
})
export class StudentProfileComponent implements OnInit {
  @Input() isAdmin = false;
  @Input() profile: Student = {
    ParentID1: '',
    ParentID2: '',
    Name: '',
    Surname: '',
    ID: '',
    Title: '',
    Gender: '',
    HealthProblems: '',
    Notes: '',
    Group: null,
    DOB: ''
  };
  studentClass: Group;
  groups;
  loading = false;
  parent: Parent;
  faView = faEye;
  faUser = faUser;
  currentUser;
  @Output() emitProfile: EventEmitter<any> = new EventEmitter();
  @Output() emitMedical = new EventEmitter<Object>();
  @ViewChild('stepper') private stepper: MatStepper;
  constructor(private authService: AuthService,private dataService: ParentDataService, private fb: UntypedFormBuilder, private breakpointObserver: BreakpointObserver, private studentService: StudentService, private router: Router, private _snackBar: MatSnackBar, private groupService: GroupService) {
    this.authService.$User.subscribe((res)=>{
      this.isAdmin = res?.get('role') === 'admin';
      this.currentUser = res;
    })
  }
  profileForm: UntypedFormGroup = this.fb.group({
    ParentID1: ['', Validators.required],
  ParentID2: [''],
  Name: ['', Validators.required],
  Surname: ['', Validators.required],
  ID: ['', Validators.required, Validators.length > 6],
  Gender: ['', Validators.required],
  Notes: [''],
  DOB: [''],
  Group: [undefined]});
  medicalForm: UntypedFormGroup;
  thirdFormGroup = this.fb.group({
    thirdCtrl: ['', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;
  dataLoaded: Promise<boolean>;
  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.studentService.activatedStudent$.subscribe(async s => {
      this.studentClass = s?.Group;
      return this.profile = s;
    });
    this.groupService.groups$.subscribe(async groups => {
      return this.groups = groups;
    });
    if(this.profile){
    if(!this.isAdmin){
          this.dataService.parent$.subscribe(async p => { this.parent = p });
          const parentId1 = this.parent?.isMainContact ? this.parent?.ID : this.profile.ParentID1;
          const parentId2 = !this.parent?.isMainContact ? this.parent?.ID : this.profile.ParentID2;
          this.profileForm.patchValue({
            ParentID1: parentId1,
            ParentID2: parentId2,
            Name: this.profile.Name,
            Surname: this.profile.Surname,
            ID: this.profile.ID,
            Gender: this.profile.Gender,
            Notes: this.profile.Notes,
            Group: this.studentClass,
            DOB: new Date(this.profile.DOB)
          });
      }else{
        this.profileForm.patchValue({
          ParentID1: this.profile.ParentID1,
          ParentID2: this.profile.ParentID2,
          Name: this.profile.Name,
          Surname: this.profile.Surname,
          ID: this.profile.ID,
          Gender: this.profile.Gender,
          Notes: this.profile.Notes,
          Group: this.studentClass,
          DOB: new Date(this.profile.DOB)
        });
      }

    }else{
      this.profileForm.controls['ParentID1'].setValue(this.currentUser?.get('ID'));
    }
    
    this.dataLoaded = Promise.resolve(true);
    this.loading = false;
  }
  async saveProfile(){
    if(this.profileForm.dirty){
      this.loading = true;
      this.studentService
      .saveStudent({
          ParentID1: this.profileForm.controls['ParentID1'].value,
          ParentID2: this.profileForm.controls['ParentID2'].value,
          Name: this.profileForm.controls['Name'].value,
          Surname: this.profileForm.controls['Surname'].value,
          ID: this.profileForm.controls['ID'].value,
          Gender: this.profileForm.controls['Gender'].value,
          Notes: this.profileForm.controls['Notes'].value,
          Group: this.profileForm.controls['Group'].value,
          DOB: moment(this.profileForm.controls['DOB'].value).format('MMM DD yyyy')
      })
      .then((res) => {
        this.loading = false;
        this.studentService.newStudentEmail = undefined;
        this.openSnackBar('Student saved');
        this.emitProfile.emit(res);
        this.profileForm.reset();
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.loading = false;
      });
    } else {
      this.emitProfile.emit(this.profile);
    }
  }
  async saveMedicalDetails(){
    if(this.medicalForm.dirty){
      this.loading = true;
      const medicalForm = {...this.medicalForm.value, 
        ID: this.profile.ID};

        this.studentService
        .saveStudentMedicalDetails(medicalForm)
        .then(() => {
          this.openSnackBar('Medical details saved');
          this.loading = false;
          // this.stepper.next();
        })
        .catch((err) => {
          this.openSnackBar(err);
          this.loading = false;
        });
      this.medicalForm.markAsPristine();
    } else {
      this.emitProfile.emit(this.profile);
    }
  }
  getErrorMessage() {
    if (this.profileForm.controls['Name'].hasError('required')) {
      return 'You must enter a value';
    }
  
    return this.profileForm.controls['Name'].hasError('required') ? 'Not a valid name' : '';
  }

  async saveStudentConsent(event: {student: StudentConsent, signature: string}){
    this.studentService
      .saveStudentConsent(event.student)
      .then(() => {
        this.openSnackBar('Consent saved');
        this.emitProfile.emit(event.student);
      })
      .catch((err) => {
        this.openSnackBar(err);
        this.loading = false;
      });
  }


  back(){
    this.stepper.previous();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000
    });
  }

  viewParent(id: string){
    this.router.navigate(['admin','parent', id])
  }

  selectDate(e: any){
    console.log(e)
    this.profileForm.patchValue({'DOB': e.value.toDateString()});
  }
  
}



