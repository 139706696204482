<div class="p-5 bg-50perc-white">
    <div class="text-center mb-4">
        <h1>Teken in</h1>
    </div>
    <form class="user" [formGroup]="loginForm" (submit)="login()">
        <div class="d-flex flex-column">
            <mat-form-field appearance="outline" class="flex-1">
                <mat-label>Email</mat-label>
                <input matInput name="email" type="email" placeholder="Email" formControlName="email">
              </mat-form-field>
              <mat-form-field appearance="outline" class="flex-1">
                <mat-label>Password</mat-label>
                <input matInput [type]="!passwordVisible ? 'password' : 'text'" formControlName="password" name="password" placeholder="Password" id="password" name="password">
                <button type="button" mat-icon-button matSuffix (click)="passwordVisible = !passwordVisible" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordVisible">
                  <mat-icon>{{passwordVisible ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
              <div class="text-right">
                <button type="button" mat-button routerLink="/forgot-password">Reset Password</button>
            </div>
        </div>

        <p class="invalid-field">{{errorMessage}}</p>
        <button mat-raised-button [disabled]="loading" color="accent" type="submit" class="btn-block">
            <span>Teken in</span>
        </button>

        <mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'" [value]="0">
        </mat-progress-bar>
    </form>
    <div class="d-flex flex-column mt-4">
        <span>Nognie 'n lid nie?</span>  
        <button type="button" mat-stroked-button routerLink="/register" class="btn-block mt-3">Registreer</button>
    </div>
</div>