import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBook, faCalendar, faCheckDouble, faChevronDown, faChevronLeft, faChevronRight, faChurch, faList, faPhotoVideo, faPlay, faQuoteLeft, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { DataService } from 'src/app/services/data.service';
import * as moment from 'moment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  albumList = [];

  loading = false;
  faNext = faChevronRight;

  faPrev = faChevronLeft;
  faDown = faChevronDown;
  faPhoto = faPhotoVideo;
  faCalendar = faCalendar;
  faQuoteLeft = faQuoteLeft;
  faCheckList = faCheckDouble;
  CalendarView = CalendarView;

  viewDate: Date = new Date();
  minTime = this.viewDate.getHours()-1;
  maxTime = this.viewDate.getHours()+3;
  events: CalendarEvent[] = [
  ];

  timelineEvents = [{
    id: 0,
    description: "20 days ago",
    timestamp: moment().subtract(20, 'days').toDate(),
    title: "Milestone 1"},
    {
      id: 1,
      description: "30 days ago",
      timestamp: moment().subtract(30, 'days').toDate(),
      title: "Milestone 2"},
    {
      id: 2,
      description: "40 days ago",
      timestamp: moment().subtract(40, 'days').toDate(),
      title: "Milestone 3"}];
  constructor(private dataService: DataService, private router: Router) {
    setInterval(() => {
      this.viewDate = new Date();
    }, 60000);
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.dataService.albums$.subscribe(res => {
      this.albumList = [];
      res?.forEach(a => { 
        this.albumList.push({
          coverPhotoBaseUrl: 'url("'+a.coverPhotoBaseUrl+'")',
          title: a.title.split('_').pop()
        })
      })
    })
    this.loading = false;
  }
  newAppointment(): void{
    this.router.navigateByUrl('appointment');
  }

  docuMerge(): void{
    this.router.navigateByUrl('pdf-worker');
  }


  
}
