<div class="main-wrapper">

    <mat-toolbar class="d-flex justify-content-between bg-white mt-3">
        <div class="desktop">
            <button mat-button routerLink="home" routerLinkActive="active">
                <fa-icon [icon]="faHome"></fa-icon>
            </button>
            <button mat-button routerLink="about" routerLinkActive="active">
                Meer oor Kruin
            </button>
            <button mat-button routerLink="" routerLinkActive="" disabled>
                Wat bied ons
            </button>
            <button mat-button routerLink="" routerLinkActive="" disabled>
                Word deel
            </button>
            <button mat-button routerLink="fotos" routerLinkActive="active">
                Fotos
            </button>
            <button mat-button routerLink="contact" routerLinkActive="active">
                Kontak ons
            </button>
        </div>
        <div class="mobile">
            <button mat-stroked-button color="accent" [matMenuTriggerFor]="menu">MENU</button>
            <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="home" routerLinkActive="active">
                        Home
                    </button>
                    <button mat-button routerLink="about" routerLinkActive="active">
                        Meer oor Kruin
                    </button>
                    <button mat-button routerLink="" routerLinkActive="" disabled>
                        Wat bied ons
                    </button>
                    <button mat-button routerLink="" routerLinkActive="" disabled>
                        Word deel
                    </button>
                    <button mat-button routerLink="fotos" routerLinkActive="active">
                        Fotos
                    </button>
                    <button mat-button routerLink="contact" routerLinkActive="active">
                        Kontak ons
                    </button>
                <button mat-menu-item (click)="Login()">Login</button>
            </mat-menu>
        </div>
        <div class="spacer"></div>
        <div class="desktop">
            <button class="bg-black text-white" mat-raised-button (click)="Login()">Teken in / Registreer</button>
        </div>
        <div class="pl-3 pr-3">
            <img class="logo-md" src="assets/logo.png"/>
        </div>
    </mat-toolbar>
    <div class="p-0 dashboard-container flex-1">
        <router-outlet></router-outlet>
    </div>

</div>


