import { Component, OnInit } from '@angular/core';
import { faBook, faCalendar, faChurch, faPlay, faVolumeUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit {
  faYoutube = faPlay;
  faSoundcloud = faVolumeUp;
  eredienste = [
    {
      title: 'Oggenddiens',
      time: '9:00'
    },
    {
      title: 'Kinderkerk',
      time: '9:00'
    },
    {
      title: 'Aanddiens',
      time: '17:15'
    },
    {
      title: 'Jeugkerk',
      time: '18:15'
    }
  ]
  gebeure = [{
      date: '30 Aug 2024, 12:00',
      title: 'Kruin Fees',
    }];
  faBook = faBook;
  faChurch = faChurch;
  faCalendar = faCalendar;
  constructor() { }

  ngOnInit(): void {
  }

}
