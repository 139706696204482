<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>


<div *ngIf="!loading">
  <div class="d-flex justify-content-center">
    <mat-form-field color="accent">
      <mat-label>Date</mat-label>
      <input readonly matInput (dateChange)="selectDate($event)" [matDatepicker]="picker1" [ngModel]="date">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
  </div>
  <form class="user" [formGroup]="checkInForm" (ngSubmit)="saveReport()">
    <div class="d-flex flex-column mb-3 mt-3">
      <!-- Check in-->

    <div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="CheckIn">
      <div class="d-flex align-items-center flex-wrap">
        <div class="d-flex align-items-center flex-1 justify-content-around">
        <!-- <mat-radio-group aria-labelledby="sleep3" formControlName="sleep" class="d-flex">
          <mat-radio-button class="example-radio-button mr-3" [value]="'happy'"> -->
            <div class="btn bg-primary link" (click)="setPresence('Present')" [ngClass]="{'bg-secondary': this.checkInForm.controls['CheckIn'].value === 'Present'}">Present</div>
          
            <div class="btn bg-primary link" (click)="setPresence('Absent')" [ngClass]="{'bg-secondary': this.checkInForm.controls['CheckIn'].value === 'Absent'}">Absent</div>
          
            <div class="btn bg-primary link" (click)="setPresence('Late')" [ngClass]="{'bg-secondary': this.checkInForm.controls['CheckIn'].value === 'Late'}">Late</div>
          
      </div>
      </div>
    </div>
    </div>
<div class="d-flex flex-column mb-3 mt-3">
  <!-- Check in Mood -->
  <h3>How's {{student.Name}} doing today?</h3>
<div class="bg-grey p-2 mb-2 border-radius-5" formGroupName="CheckInMood">
  <div class="d-flex align-items-center flex-wrap">
    <div class="d-flex align-items-center flex-1 justify-content-around">
    <!-- <mat-radio-group aria-labelledby="sleep3" formControlName="sleep" class="d-flex">
      <mat-radio-button class="example-radio-button mr-3" [value]="'happy'"> -->
        <div class="mood link" (click)="setMood('Happy')" [ngClass]="{'active-mood': this.checkInForm.controls['CheckInMood'].value === 'Happy'}"><fa-icon class="text-success" size="5x" [icon]="faHappy"></fa-icon></div>
      
        <div class="mood link" (click)="setMood('Neutral')" [ngClass]="{'active-mood': this.checkInForm.controls['CheckInMood'].value === 'Neutral'}"><fa-icon class="text-warning" size="5x" [icon]="faNeutral"></fa-icon></div>
      
        <div class="mood link" (click)="setMood('Sad')" [ngClass]="{'active-mood': this.checkInForm.controls['CheckInMood'].value === 'Sad'}"><fa-icon class="text-danger" size="5x" [icon]="faSad"></fa-icon></div>
      
  </div>
  </div>
</div>
</div>
<div class="d-flex flex-column mb-3 mt-3">
    <h3>Check in notes</h3>
      <mat-form-field appearance="outline">
        <mat-label>Notes</mat-label>
        <input matInput placeholder="CheckInNotes" formControlName="CheckInNotes" />
      </mat-form-field>
    </div>


    <mat-toolbar class="d-flex justify-content-end">
      <button
        type="submit"
        [disabled]="!checkInForm.valid || loading"
        mat-raised-button
        color="primary"
      >
        Save
      </button>
    </mat-toolbar>
  </form>
</div>
