<div
  class="d-flex justify-content-center loader flex-column align-items-center"
  *ngIf="loading"
>
  <div>
    <mat-spinner color="accent"></mat-spinner>
  </div>
</div>


<div class="row m-0">
  <div class="col-md-9 p-0">
    <div class="marketing-banner h-100">

    </div>
  </div>
  <div class="col-md-3">
    <app-info-panel></app-info-panel>
  </div>
</div>
<div class="row m-0">
  <div class="section-title">
    <h1 class="mat-display-3 m-0">Huise wat Jesus volg</h1>
    <h2 class="mat-display-1 m-0">Leef dit.</h2>
  </div>
  <app-bedieninge></app-bedieninge>
</div>

<div class="row m-0 bg-grey">
  <div class="col-md-6">
    <img class="img-fluid" src="./../../../assets/ontvangs.png"/>
  </div>
  <div class="col-md-6 p-3">
    <div class="section-title">
      <h2 class="mat-display-1 m-0">Kontak gerus ons kerkkantoor vir enige navrae</h2>
    </div>
    <app-contact-form></app-contact-form>
  </div>
</div>

<div class="row m-0 bg-black">
  <div class="col-md-3 p-3">
    <div class="section-title">
      <h3 class="m-0 text-white">Vinnige skakels</h3>
    </div>    
  </div>
  <div class="col-md-4 p-3">
    <div class="section-title">
      <h3 class="m-0 text-white">Gebeure</h3>
    </div>    

    <div class="section-title">
      <h3 class="m-0 text-white">Vorms en dokumente</h3>
    </div>    
  </div>
  <div class="col-md-5 p-3">
    <div class="section-title">
      <h2 class="mat-display-2 m-0 text-white">Kruin-nuus</h2>
      <h2 class="mat-display-1 m-0 text-white">Jy kan weet wat ons weet.</h2>
    </div>
    <app-newsletter-form></app-newsletter-form>
  </div>
</div>

