import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService) { }
  faHome = faHome
  ngOnInit(): void {
    this.dataService.getAlbums('kruin');
  }

  Login(): void{
    this.router.navigateByUrl('auth');
  }
}
