import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  loading = false;
  contactForm: UntypedFormGroup;
  constructor(private fb: FormBuilder) { }
  ngOnInit(): void {
      this.contactForm = this.fb.group(
        {
          name: [''],
          surname: [''],
          email: [''],
          mobile: [''],
          message: ['']
      });
  }
  submit(): void{
    this.loading=true;
    setTimeout(() => {
      this.loading=false;    
    }, 1000);
  
  }

}
